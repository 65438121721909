import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/tabbar/HomeView.vue'
import store from '../store/'
// import { TOKEN } from '@/const/storeConst'
import { jmRouters } from '@/router/jmRouter'
import { newModeRoute, boomModeRoute } from '@/router/new-mode'
import { qyRoute } from '@/router/qy-router'
import { activityRoute } from '@/router/activity'
import { getStore } from '@/utils/store'
import { getHtml, parserScript, compareScript } from '@/utils/update'
import { isWeChat, isWorkWechat } from '@/utils/utils'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      title: '首页',
      depth: 0,
    },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/login.vue'),
    meta: {
      title: '登录',
    },
  },
  {
    path: '/cityLogin',
    name: 'cityLogin',
    component: () => import('@/views/login/login.vue'),
    meta: {
      title: '登录',
    },
  },
  {
    path: '/goods',
    name: 'goods',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '@/views/tabbar/GoodsView.vue'),
    meta: {
      title: '分类',
      keepAlive: true,
      depth: 0,
    },
  },
  {
    path: '/work',
    name: 'work',
    component: () => import('@/views/tabbar/WorkView.vue'),
    meta: {
      title: '工作台',
    },
  },
  {
    path: '/mine',
    name: 'mine',
    component: () => import('@/views/tabbar/MineView.vue'),
    meta: {
      title: '我的',
    },
  },
  {
    path: '/cloud-coupon',
    name: 'cloud-coupon',
    component: () => import('@/views/coupon/myCoupon.vue'),
    meta: {
      title: '提货券',
    },
  },
  {
    path: '/mine-subAcct',
    name: 'mineSubAcct',
    component: () => import('@/views/tabbar/MineViewSubAcct.vue'),
    meta: {
      title: '我的',
    },
  },
  {
    title: '扫码页面',
    name: 'scanCodePage',
    path: '/scanCodePage',
    component: () => import('@/components/ScanCodePage.vue'),
  },
  {
    name: 'mySalesList',
    path: '/mySalesList',
    component: () => import('@/views/mySalesList'),
    meta: {
      title: '我的销售单',
      keepAlive: true,
      depth: 1,
    },
  },
  {
    name: 'newSalesList',
    path: '/newSalesList',
    component: () => import('@/views/mySalesList/newIndex.vue'),
    meta: {
      title: '我的销售单',
      keepAlive: true,
      depth: 1,
    },
  },
  {
    name: 'salesDetail',
    path: '/mySalesList/salesDetail',
    component: () => import('@/views/mySalesList/salesDetail.vue'),
    meta: {
      title: '销售订单详情',
      depth: 2,
    },
  },
  {
    name: 'deliveryDetail', // 销售发货
    path: '/deliveryDetail',
    component: () => import('@/views/mySalesList/deliveryDetail.vue'),
    meta: {
      title: '发货详情',
    },
  },
  {
    name: 'deliverView',
    path: '/deliverView',
    component: () => import('@/views/mySalesList/deliverView.vue'),
    meta: {
      title: '订单发货',
    },
  },
  {
    name: 'saleList',
    path: '/saleList',
    component: () => import('@/views/saleList'),
    meta: {
      title: '我的销售售后单',
      keepAlive: true,
      depth: 1,
    },
  },
  {
    name: 'saleAfterSaleNew',
    path: '/saleAfterSaleNew',
    component: () => import('@/views/saleList/saleAfterSaleNew.vue'),
    meta: {
      title: '我的销售售后单',
      keepAlive: true,
      depth: 1,
    },
  },
  {
    name: 'myPurchaseList',
    path: '/myPurchaseList',
    component: () => import('@/views/myPurchaseList'),
    meta: {
      title: '采购订单',
      keepAlive: true,
      depth: 1,
    },
  },
  {
    name: 'purchaseAfterSaleList',
    path: '/myPurchaseList/saleList',
    component: () => import('@/views/myPurchaseList/saleList'),
    meta: {
      title: '采购售后',
      keepAlive: true,
      depth: 1,
    },
  },
  {
    name: 'myPurchaseList/logistics', // 销售发货
    path: '/myPurchaseList/logistics',
    component: () => import('@/views/myPurchaseList/saleList/logistics'),
    meta: {
      title: '发货详情',
    },
  },
  {
    name: 'createAfterSale',
    path: '/myPurchaseList/saleList/createAfterSale',
    component: () => import('@/views/myPurchaseList/saleList/createAfterSale'),
    meta: {
      title: '选择售后类型',
    },
  },
  {
    name: 'detail',
    path: '/myPurchaseList/saleList/detail',
    component: () => import('@/views/myPurchaseList/saleList/detail'),
    meta: {
      title: '售后单详情',
      depth: 2,
    },
  },
  // {
  //   name: 'distribution',
  //   path: '/myPurchaseList/saleList/distribution',
  //   component: () => import('@/views/myPurchaseList/saleList/distribution'),
  //   meta: {
  //     title: '售后单详情',
  //   },
  // },
  {
    name: 'createOrderNew',
    path: '/createOrderNew',
    component: () => import('@/views/newModel/createOrder'),
    meta: {
      title: '创建订单',
      keepAlive: true,
      depth: 2,
    },
  },
  {
    name: 'firstOrder',
    path: '/firstOrder',
    component: () => import('@/views/newModel/createOrder/firstOrder.vue'),
    meta: {
      title: '创建订单',
      keepAlive: true,
      depth: 2,
    },
  },
  {
    name: 'paySuccess',
    path: '/paySuccess',
    component: () => import('@/views/newModel/createOrder/paySuccess'),
    meta: {
      title: '支付成功',
      depth: 1,
    },
  },
  {
    name: 'purchaseOrderNew',
    path: '/purchaseOrderNew',
    component: () => import('@/views/newModel/purchaseOrderNew'),
    meta: {
      title: '采购订单',
      keepAlive: true,
      depth: 1,
    },
  },
  {
    name: 'purchaseAfterSaleNew',
    path: '/purchaseAfterSaleNew',
    component: () =>
      import('@/views/newModel/purchaseOrderNew/afterSaleList.vue'),
    meta: {
      title: '采购售后',
      keepAlive: true,
      depth: 1,
    },
  },
  {
    name: 'createPurchase',
    path: '/myPurchaseList/create',
    component: () => import('@/views/myPurchaseList/createPurchase'),
    meta: {
      title: '创建订单',
      keepAlive: true,
      depth: 2,
    },
  },
  {
    name: 'createTransfer',
    path: '/mySalesListList/createTransfer',
    component: () => import('@/views/mySalesList/createTransfer'),
    meta: {
      title: '订单转单',
      keepAlive: true,
      depth: 2,
    },
  },
  {
    name: 'purchaseDeliverDetail', // 采购发货
    path: '/myPurchaseList/deliverDetail',
    component: () => import('@/views/myPurchaseList/purchaseDeliverDetail.vue'),
    meta: {
      title: '发货单详情',
    },
  },
  {
    name: 'deliverDetail', // 邮费跳转发货详情
    path: '/deliverDetail',
    component: () => import('@/views/myPurchaseList/deliverDetail.vue'),
    meta: {
      title: '发货单详情',
    },
  },
  {
    name: 'purchasePay', // 订单付款
    path: '/myPurchaseList/pay',
    component: () => import('@/views/myPurchaseList/purchasePay.vue'),
    meta: {
      title: '订单付款',
    },
  },
  {
    name: 'choiceCoupon',
    path: '/choiceCoupon',
    component: () => import('@/components/choiceCoupon.vue'),
    meta: {
      title: '优惠券',
      depth: 9,
    },
  },
  {
    name: 'choiceSubject',
    path: '/choiceSubject',
    component: () => import('@/components/choiceSubject.vue'),
    meta: {
      title: '选择主体',
      depth: 9,
    },
  },
  {
    name: 'purchaseDetail',
    path: '/myPurchaseList/detail',
    component: () => import('@/views/myPurchaseList/purchaseDetail.vue'),
    meta: {
      title: '订单详情',
      keepAlive: true,
      depth: 2,
    },
  },
  {
    name: 'firstOrderPay',
    path: '/myPurchaseList/firstOrderPay',
    component: () => import('@/views/myPurchaseList/firstPay.vue'),
    meta: {
      title: '选择支付方式',
    },
  },
  {
    name: 'choiceAddress',
    path: '/choiceAddress',
    component: () => import('@/components/choiceAddress'),
    meta: {
      title: '选择地址',
      depth: 9,
    },
  },
  {
    name: 'afterSale',
    path: '/afterSale',
    component: () => import('@/views/saleList/afterSale.vue'),
    meta: {
      title: '销售售后单',
      depth: 2,
    },
  },
  {
    name: 'distribution',
    path: '/afterBehalf/distribution',
    component: () => import('@/views/saleList/distribution.vue'),
    meta: {
      title: '代发售后单分配',
    },
  },
  {
    name: 'afterSaleTraceCode',
    path: '/afterSaleTraceCode',
    component: () => import('@/views/saleList/afterSaleTraceCode.vue'),
    meta: {
      title: '售后溯源码',
    },
  },
  {
    name: '/myPurchaseList/saleList/storeCode',
    path: '/myPurchaseList/saleList/storeCode',
    component: () => import('@/views/myPurchaseList/saleList/storeCode.vue'),
    meta: {
      title: '查看售后溯源码',
    },
  },
  {
    name: 'merchanDising',
    path: '/merchanDising',
    component: () => import('@/views/merchanDising/merchanDising.vue'),
    meta: {
      title: '商品管理',
    },
  },
  {
    name: 'inventer',
    path: '/inventer',
    component: () => import('@/views/merchanDising/inventer.vue'),
    meta: {
      title: '本地发货',
    },
  },
  {
    name: 'purchaseCloud',
    path: '/purchaseCloud',
    component: () => import('@/views/purchaseCloud'),
    meta: {
      title: '我的云仓',
    },
  },
  {
    name: 'cloudCreate',
    path: '/purchaseCloud/create',
    component: () => import('@/views/purchaseCloud/create'),
    meta: {
      title: '创建云仓备货',
      keepAlive: true,
      depth: 4,
    },
  },
  {
    name: 'cloudPickUp',
    path: '/purchaseCloud/pickUp',
    component: () => import('@/views/purchaseCloud/cloudPickUp'),
    meta: {
      title: '未提备货单',
    },
  },
  {
    name: 'stockDetail',
    path: '/purchaseCloud/stockDetail',
    component: () => import('@/views/purchaseCloud/stockDetail'),
    meta: {
      title: '云仓提货详情',
      keepAlive: true,
      depth: 3,
    },
  },
  {
    name: 'cloudChangeDetail',
    path: '/purchaseCloud/change',
    component: () => import('@/views/purchaseCloud/cloudChange'),
    meta: {
      title: '云仓变动明细',
    },
  },
  {
    name: 'customerCloudDetail',
    path: '/purchaseCloud/customerCloudDetail',
    component: () => import('@/views/purchaseCloud/customerCloudDetail'),
    meta: {
      title: '客户云仓明细',
    },
  },
  {
    name: 'cloudBehalf',
    path: '/purchaseCloud/cloudBehalf',
    component: () => import('@/views/purchaseCloud/cloudBehalf'),
    meta: {
      title: '转云仓提货',
      keepAlive: true,
      depth: 2,
    },
  },
  {
    name: 'goodsList',
    path: '/goodsList',
    component: () => import('@/components/goodsList'),
    meta: {
      title: '查看商品',
      depth: 9,
    },
  },
  {
    name: 'myWallet',
    path: '/myWallet',
    component: () => import('@/views/myWallet/index'),
    meta: {
      title: '混拿货款',
    },
  },
  {
    name: 'lockBalance',
    path: '/lockBalance',
    component: () => import('@/views/myWallet/lockBalance'),
    meta: {
      title: '锁定余额',
    },
  },
  {
    name: 'customerFlow',
    path: '/customerFlow',
    component: () => import('@/views/myWallet/customerFlow'),
    meta: {
      title: '客户流水',
    },
  },
  {
    name: 'billDetail',
    path: '/billDetail',
    component: () => import('@/views/myWallet/billDetail'),
    meta: {
      title: '本人余额',
    },
  },
  {
    name: 'cashSpringWaterParticulars',
    path: '/cashSpringWaterParticulars',
    component: () => import('@/views/myWallet/cashSpringWaterParticulars'),
    meta: {
      title: '余额详情',
    },
  },
  {
    name: 'walletCoupons',
    path: '/walletCoupons',
    component: () => import('@/views/myWallet/walletCoupons'),
    meta: {
      title: '优惠券',
    },
  },
  {
    name: 'walletPickUpCoupons',
    path: '/walletPickUpCoupons',
    component: () => import('@/views/myWallet/walletPickUpCoupons'),
    meta: {
      title: '提货券',
    },
  },
  {
    name: 'giveWallet',
    path: '/giveWallet',
    component: () => import('@/views/giveWallet/index'),
    meta: {
      title: '赠送款钱包',
    },
  },
  {
    name: 'giveWalletDetail',
    path: '/giveWalletDetail',
    component: () => import('@/views/giveWallet/giveWalletDetail'),
    meta: {
      title: '赠送款明细',
    },
  },
  {
    name: 'giveWalletParticulars',
    path: '/giveWalletParticulars',
    component: () => import('@/views/giveWallet/giveWalletParticulars'),
    meta: {
      title: '赠送款详情',
    },
  },
  {
    name: 'smallSampleWallet',
    path: '/smallSampleWallet',
    component: () => import('@/views/smallSampleWallet/index'),
    meta: {
      title: '小样款钱包',
    },
  },
  {
    name: 'smallSampleWalletDetail',
    path: '/smallSampleWalletDetail',
    component: () =>
      import('@/views/smallSampleWallet/smallSampleWalletDetail'),
    meta: {
      title: '小样款明细',
    },
  },
  {
    name: 'smallSampleWalletParticulars',
    path: '/smallSampleWalletParticulars',
    component: () =>
      import('@/views/smallSampleWallet/smallSampleWalletParticulars'),
    meta: {
      title: '小样款详情',
    },
  },
  {
    name: 'postageStoredValue',
    path: '/postageStoredValue',
    component: () => import('@/views/postageStoredValue/index'),
    meta: {
      title: '充邮费(货补)',
    },
  },
  {
    name: 'postageStoredValueDetail',
    path: '/postageStoredValueDetail',
    component: () =>
      import('@/views/postageStoredValue/postageStoredValueDetail'),
    meta: {
      title: '充邮费(货补)明细',
    },
  },
  {
    name: 'postageStoredValueParticulars',
    path: '/postageStoredValueParticulars',
    component: () =>
      import('@/views/postageStoredValue/postageStoredValueParticulars'),
    meta: {
      title: '充邮费(货补)详情',
    },
  },
  {
    name: 'postage',
    path: '/postage',
    component: () => import('@/views/postage/index'),
    meta: {
      title: '用邮费(消耗)',
    },
  },
  {
    name: 'postageDetail',
    path: '/postageDetail',
    component: () => import('@/views/postage/postageDetail'),
    meta: {
      title: '用邮费(消耗)变动明细',
    },
  },
  {
    name: 'postageParticulars',
    path: '/postageParticulars',
    component: () => import('@/views/postage/postageParticulars'),
    meta: {
      title: '用邮费(消耗)详情',
    },
  },
  {
    name: 'balanceConstitute',
    path: '/balanceConstitute',
    component: () => import('@/views/postage/balanceConstitute'),
    meta: {
      title: '邮费余额组成',
    },
  },
  {
    name: 'cashPledge',
    path: '/cashPledge',
    component: () => import('@/views/cashPledge/index'),
    meta: {
      title: '押金',
    },
  },
  {
    name: 'cashPledgeDetail',
    path: '/cashPledgeDetail',
    component: () => import('@/views/cashPledge/cashPledgeDetail'),
    meta: {
      title: '押金变动明细',
    },
  },
  {
    name: 'cashPledgeParticulars',
    path: '/cashPledgeParticulars',
    component: () => import('@/views/cashPledge/cashPledgeParticulars'),
    meta: {
      title: '押金详情',
    },
  },
  {
    name: 'earnestMoney',
    path: '/earnestMoney',
    component: () => import('@/views/earnestMoney/index'),
    meta: {
      title: '保证金',
    },
  },
  {
    name: 'earnestMoneyDetail',
    path: '/earnestMoneyDetail',
    component: () => import('@/views/earnestMoney/earnestMoneyDetail'),
    meta: {
      title: '保证金变动明细',
    },
  },
  {
    name: 'earnestMoneyParticulars',
    path: '/earnestMoneyParticulars',
    component: () => import('@/views/earnestMoney/earnestMoneyParticulars'),
    meta: {
      title: '保证金详情',
    },
  },
  {
    name: 'purposeMoney',
    path: '/purposeMoney',
    component: () => import('@/views/purposeMoney/index'),
    meta: {
      title: '意向金',
    },
  },
  {
    name: 'purposeMoneyDetail',
    path: '/purposeMoneyDetail',
    component: () => import('@/views/purposeMoney/purposeMoneyDetail'),
    meta: {
      title: '意向金变动明细',
    },
  },
  {
    name: 'purposeMoneyParticulars',
    path: '/purposeMoneyParticulars',
    component: () => import('@/views/purposeMoney/purposeMoneyParticulars'),
    meta: {
      title: '意向金详情',
    },
  },
  {
    name: 'upBillList',
    path: '/upBillList',
    component: () => import('@/views/upBill'),
    meta: {
      title: '上账列表',
    },
  },
  {
    path: '/upDownBillList',
    name: 'upDownBillList',
    component: () => import('@/views/upBill/upDownBillList'),
    meta: {
      title: '上下账列表',
    },
  },
  {
    name: 'upBillAudit',
    path: '/upBillAudit',
    component: () => import('@/views/upBill/audit'),
    meta: {
      title: '上账审核',
    },
  },
  {
    name: 'upBillApplication',
    path: '/upBillApplication',
    component: () => import('@/views/upBill/upBillApplication'),
    meta: {
      title: '申请上账',
    },
  },
  {
    name: 'downBillList',
    path: '/downBillList',
    component: () => import('@/views/downBill'),
    meta: {
      title: '下账列表',
    },
  },
  {
    name: 'downBillAudit',
    path: '/downBillAudit',
    component: () => import('@/views/downBill/audit'),
    meta: {
      title: '下账审核',
    },
  },
  {
    name: 'downBillApplication',
    path: '/downBillApplication',
    component: () => import('@/views/downBill/downBillApplication'),
    meta: {
      title: '申请下账',
    },
  },
  {
    name: 'chooseType', // 选择开通账号类型
    path: '/chooseType',
    component: () => import('@/views/incomePart/chooseType'),
    meta: {
      title: '开通账户',
    },
  },
  {
    name: 'bindCardSingle', // 个体和商户绑定银行卡页面
    path: '/bindCardSingle',
    component: () => import('@/views/incomePart/bindCardSingle'),
    meta: {
      title: '绑定银行卡',
    },
  },
  {
    name: 'verifyMobile', // 验证银行卡预留手机号
    path: '/verifyMobile',
    component: () => import('@/views/incomePart/verifyMobile'),
    meta: {
      title: '绑定银行卡',
    },
  },
  {
    name: 'bindProcess', // 绑卡处理中页面
    path: '/bindProcess',
    component: () => import('@/views/incomePart/bindProcess'),
    meta: {
      title: '绑定银行卡',
    },
  },
  {
    name: 'bindFail', // 绑卡失败
    path: '/bindFail',
    component: () => import('@/views/incomePart/bindFail'),
    meta: {
      title: '绑定银行卡',
    },
  },
  {
    name: 'openAccountSingle', // 填写个人开户资料
    path: '/openAccountSingle',
    component: () => import('@/views/incomePart/openAccountSingle'),
    meta: {
      title: '填写开户资料',
    },
  },
  {
    name: 'openAccountPart1', // 填写个体开户资料1
    path: '/openAccountPart1',
    component: () => import('@/views/incomePart/openAccountPart1'),
    meta: {
      title: '填写开户资料',
    },
  },
  {
    name: 'openAccountPart2', // 填写个体开户资料2
    path: '/openAccountPart2',
    component: () => import('@/views/incomePart/openAccountPart2'),
    meta: {
      title: '填写开户资料',
    },
  },
  {
    name: 'openAccountBusiness1', // 填写企业开户资料1
    path: '/openAccountBusiness1',
    component: () => import('@/views/incomePart/openAccountBusiness1'),
    meta: {
      title: '填写开户资料',
    },
  },
  {
    name: 'openAccountBusiness2', // 填写企业开户资料2
    path: '/openAccountBusiness2',
    component: () => import('@/views/incomePart/openAccountBusiness2'),
    meta: {
      title: '填写开户资料',
    },
  },
  {
    name: 'openAccountProcess', // 开户审核
    path: '/openAccountProcess',
    component: () => import('@/views/incomePart/openAccountProcess'),
    meta: {
      title: '开户审核',
    },
  },
  {
    name: 'openAccountPass', // 开户审核通过
    path: '/openAccountPass',
    component: () => import('@/views/incomePart/openAccountPass'),
    meta: {
      title: '开户审核',
    },
  },
  {
    name: 'openAccountFail', // 开户审核不通过
    path: '/openAccountFail',
    component: () => import('@/views/incomePart/openAccountFail'),
    meta: {
      title: '开户审核',
    },
  },
  {
    name: 'bindCardBusiness', // 企业绑卡
    path: '/bindCardBusiness',
    component: () => import('@/views/incomePart/bindCardBusiness'),
    meta: {
      title: '填写绑卡资料',
    },
  },
  {
    name: 'verifyMobileBusiness', // 企业绑卡验证
    path: '/verifyMobileBusiness',
    component: () => import('@/views/incomePart/verifyMobileBusiness'),
    meta: {
      title: '绑定银行卡',
    },
  },
  {
    name: 'signAppoint', // 企业绑卡验证
    path: '/signAppoint',
    component: () => import('@/views/incomePart/signAppoint'),
    meta: {
      title: '签约',
    },
  },
  {
    name: 'appointProcess', // 签约处理中
    path: '/appointProcess',
    component: () => import('@/views/incomePart/appointProcess'),
    meta: {
      title: '签约',
    },
  },
  {
    name: 'appointFail', // 签约失败
    path: '/appointFail',
    component: () => import('@/views/incomePart/appointFail'),
    meta: {
      title: '签约',
    },
  },
  {
    name: 'appointSuccess', // 签约失败
    path: '/appointSuccess',
    component: () => import('@/views/incomePart/appointSuccess'),
    meta: {
      title: '签约',
    },
  },
  {
    name: 'payApplyFail', // 聚合支付失败
    path: '/payApplyFail',
    component: () => import('@/views/incomePart/payApplyFail'),
    meta: {
      title: '我的收益',
    },
  },
  {
    name: 'payApply', // 聚合支付申请成功
    path: '/payApply',
    component: () => import('@/views/incomePart/payApply'),
    meta: {
      title: '我的收益',
    },
  },
  {
    name: 'myCollectPart', // 我的收款详细页面
    path: '/myCollectPart',
    component: () => import('@/views/incomePart/myCollectPart'),
    meta: {
      title: '我的收益',
    },
  },
  {
    name: 'myCollectPartBranch', // 我的收款详细页面
    path: '/myCollectPartBranch',
    component: () => import('@/views/incomePart/myCollectPartBranch'),
    meta: {
      title: '我的收益',
    },
  },
  {
    name: 'profitStatic', // 收益统计
    path: '/profitStatic',
    component: () => import('@/views/incomePart/profitStatic'),
    meta: {
      title: '收益统计',
    },
  },
  {
    name: 'myCollectAll', // 我的收款详细页面全部
    path: '/myCollectAll',
    component: () => import('@/views/incomePart/myCollectAll'),
    meta: {
      title: '我的收益',
    },
  },
  {
    name: 'myUsableBalance', // 我的可用余额
    path: '/myUsableBalance',
    component: () => import('@/views/incomePart/myUsableBalance'),
    meta: {
      title: '我的收益',
    },
  },
  {
    name: 'accountDetail', // 账单详情
    path: '/accountDetail',
    component: () => import('@/views/incomePart/accountDetail'),
    meta: {
      title: '收益详情',
    },
  },
  {
    name: 'cashout', // 提现页面
    path: '/cashout',
    component: () => import('@/views/myCenterAndBank/cashout'),
    meta: {
      title: '提现',
    },
  },
  {
    name: 'cashOutBranch', // 提现页面
    path: '/cashOutBranch',
    component: () => import('@/views/myCenterAndBank/cashOutBranch'),
    meta: {
      title: '提现',
    },
  },
  {
    name: 'cashApplySuccess', // 提现成功页面
    path: '/cashApplySuccess',
    component: () => import('@/views/myCenterAndBank/cashApplySuccess'),
    meta: {
      title: '提现成功',
    },
  },
  {
    name: 'myCenter', // 我的个人中心
    path: '/myCenter',
    component: () => import('@/views/myCenterAndBank/myCenter'),
    meta: {
      title: '个人中心',
    },
  },
  {
    name: 'privacyAgree', // 我的个人中心
    path: '/privacyAgree',
    component: () => import('@/views/myCenterAndBank/privacyAgree'),
    meta: {
      title: '隐私政策',
    },
  },
  {
    name: 'payPassword', // 支付密码
    path: '/payPassword',
    component: () => import('@/views/myCenterAndBank/payPassword'),
    meta: {
      title: '支付密码',
    },
  },
  {
    name: 'loginPassword', // 登录密码
    path: '/loginPassword',
    component: () => import('@/views/myCenterAndBank/loginPassword'),
    meta: {
      title: '登录密码',
    },
  },
  {
    name: 'bindMobile', // 绑定手机
    path: '/bindMobile',
    component: () => import('@/views/myCenterAndBank/bindMobile'),
    meta: {
      title: '绑定手机',
    },
  },
  {
    name: 'recharge', // 充值
    path: '/recharge',
    component: () => import('@/views/myCenterAndBank/recharge'),
    meta: {
      title: '充值',
    },
  },
  {
    name: 'debitCard', // 银行卡
    path: '/debitCard',
    component: () => import('@/views/myCenterAndBank/debitCard'),
    meta: {
      title: '银行卡',
    },
  },
  {
    name: 'addCard', // 添加银行卡
    path: '/addCard',
    component: () => import('@/views/myCenterAndBank/addCard'),
    meta: {
      title: '添加新储蓄卡',
    },
  },
  {
    name: 'userOauth', // 添加银行卡
    path: '/userOauth',
    component: () => import('@/views/myCenterAndBank/userOauth'),
    meta: {
      title: '登录',
    },
  },
  {
    name: 'appointForm', // 签约跳转
    path: '/appointForm',
    component: () => import('@/views/incomePart/appointForm'),
    meta: {
      title: '签约',
    },
  },
  // {
  //   name: 'promotion', // 首页公司大促列表
  //   path: '/companyBigPromotion/index',
  //   component: () => import('@/views/companyBigPromotion/index'),
  //   meta: {
  //     title: '活动列表',
  //   },
  // },
  {
    name: 'topUpActivity', // 首页公司大促列表
    path: '/topUpActivity/index',
    component: () => import('@/views/topUpActivity/index'),
    meta: {
      title: '充值活动',
      keepAlive: true,
    },
  }, // 修改充值活动
  {
    name: 'selectStall', // 首页公司大促列表
    path: '/topUpActivity/selectStall',
    component: () => import('@/views/topUpActivity/selectStall'),
    meta: {
      title: '选档',
    },
  }, // 修改充值活动
  // {
  //   name: 'eventParticulars', // 首页公司大促列表详情
  //   path: '/companyBigPromotion/eventParticulars',
  //   component: () => import('@/views/companyBigPromotion/eventParticulars'),
  //   meta: {
  //     title: '活动详情',
  //   },
  // },
  {
    name: 'rightsParticulars', // 首页公司大促列表
    path: '/topUpActivity/rightsParticulars',
    component: () => import('@/views//topUpActivity/rightsParticulars'),
    meta: {
      title: '权益详情',
    },
  }, // 修改充值活动-权益详情
  // {
  //   name: 'returnParticulars', // 首页公司大促列表详情 returnParticulars
  //   path: '/companyBigPromotion/returnParticulars',
  //   component: () => import('@/views/companyBigPromotion/returnParticulars'),
  //   meta: {
  //     title: '活动详情',
  //   },
  // },
  {
    name: 'stallInformation', // 首页公司大促列表详情 returnParticulars
    path: '/topUpActivity/stallInformation',
    component: () => import('@/views/topUpActivity/stallInformation'),
    meta: {
      title: '档位信息',
    },
  }, // 修改充值活动-权益详情-档位信息
  {
    name: 'participationParticulars', // 首页公司大促列表详情 returnParticulars
    path: '/topUpActivity/participationParticulars',
    component: () => import('@/views/topUpActivity/participationParticulars'),
    meta: {
      title: '客户参与详情',
    },
  }, // 修改充值活动-权益详情-客户参与详情
  {
    name: 'rightsParticularsRights', // 首页公司大促列表详情 returnParticulars
    path: '/topUpActivity/rightsParticularsRights',
    component: () => import('@/views/topUpActivity/rightsParticularsRights'),
    meta: {
      title: '活动列表',
    },
  }, // 修改充值活动-权益详情-客户参与详情-权益
  {
    name: 'topUpPrivate', // 首页公司大促列表详情充值
    path: '/companyBigPromotion/topUpPrivate',
    component: () => import('@/views/companyBigPromotion/topUp/topUpPrivate'),
    meta: {
      title: '余额充值',
    },
  },
  {
    name: 'topUp', // 首页公司大促列表充值
    path: '/companyBigPromotion/topUp',
    component: () => import('@/views/companyBigPromotion/topUp'),
    meta: {
      title: '余额充值',
    },
  },
  {
    name: 'payResult', // 付款结果
    path: '/payResult',
    component: () => import('@/components/payResult'),
    meta: {
      title: '付款',
    },
  },
  {
    name: 'customerPayApply', // 企业签约填写客服电话
    path: '/customerPayApply',
    component: () => import('@/views/incomePart/customerPayApply'),
    meta: {
      title: '付款',
    },
  },
  {
    name: 'subAccount', // 企业签约填写客服电话
    path: '/sub-account',
    component: () => import('@/views/subAccount/index'),
    meta: {
      title: '子账号管理',
    },
  },
  {
    name: 'addSubAccount', // 企业签约填写客服电话
    path: '/addSubAccount',
    component: () => import('@/views/subAccount/addAccount'),
    meta: {
      title: '添加子账号',
    },
  },
  {
    name: 'viewSubAccount', // 企业签约填写客服电话
    path: '/viewSubAccount',
    component: () => import('@/views/subAccount/viewAccount'),
    meta: {
      title: '查看子账号',
    },
  },
  {
    name: 'zfbPay', // 企业签约填写客服电话
    path: '/zfbPay',
    component: () => import('@/views/myCenterAndBank/zfbPay'),
    meta: {
      title: '支付宝',
    },
  },
  {
    name: 'year-end-activity',
    path: '/activities/yearEnd',
    component: () => import('@/views/activities/yearEnd'),
    meta: {
      title: '年终活动',
    },
  },
  {
    name: 'year-promotion',
    path: '/activities/yearPromotion',
    component: () => import('@/views/activities/yearPromotion'),
    meta: {
      title: '活动看板',
    },
  },
  {
    name: 'openMarket',
    path: '/activities/openMarket',
    component: () => import('@/views/activities/openMarket'),
    meta: {
      title: '2023开门红活动',
    },
  },
  ...jmRouters,
  ...newModeRoute,
  ...boomModeRoute,
  ...qyRoute,
  ...activityRoute,
]

const router = new VueRouter({
  routes,
  scrollBehavior(to, from, savedPosition) {
    // 如果存在 savedPosition，则滚动到保存的位置
    if (savedPosition) {
      return savedPosition
    } else {
      // 否则，滚动到页面顶部
      return { x: 0, y: 0 }
    }
  },
})

const whiteList = [
  'login',
  'cityLogin',
  'resetPassword',
  'inviteAgent',
  'inviteAgent-newMode',
  'userOauth',
  'inviteAgentSuccess',
  'new-inviteAgentSuccess',
  'zfbPay',
  'privacyPolicy',
  'wx-login',
  'phone-login',
  'login-privacy',
  'invite-register',
  'register-success',
  'qyInviteRegister',
  'qyInviteRegisterSuccess',
  'branchInvite',
  'inviteRecharge',
]

router.beforeEach(async (to, from, next) => {
  if (to.meta.title) {
    //判断是否有标题
    document.title = to.meta.title
  } else {
    document.title = '三草代理平台'
  }
  if (!whiteList.includes(to.name) && !store.getters.access_token) {
    // 拦截登录
    next({
      name: 'wx-login',
      replace: true,
      query: { redirect: to.fullPath },
    })
  } else {
    if (
      store.getters.customerInfo.status === 'unauthorized' &&
      to.name !== 'unauthorized'
    ) {
      next({
        name: 'unauthorized',
        replace: true,
        query: { redirect: to.fullPath },
      })
    } else {
      const newHtml = await getHtml()
      const newScript = parserScript(newHtml)
      const oldScript = getStore({ name: 'oldScript' })

      if (oldScript && compareScript(oldScript, newScript)) {
        if (isWeChat() || isWorkWechat()) {
          const len = window.location.href.indexOf('?')
          if (len > 0) {
            window.location.href =
              window.location.href.substring(0, len) + '?' + Math.random()
          } else {
            window.location.href = window.location.href + '?' + Math.random()
          }
        } else {
          parent.document.location.reload()
        }
      }
      if (
        ['login', 'cityLogin'].includes(to.name) &&
        store.getters.access_token
      ) {
        next('/')
      } else {
        if (to.name === 'mine' || to.name === 'home') {
          if (
            store.getters.saleMode &&
            ['head_office', 'branchOffice'].includes(
              store.getters.currentSelectRole?.companyPartnerType
            )
          ) {
            next('/new-mine')
          } else {
            next()
          }
        } else if (to.name === 'newMineView') {
          if (store.getters.saleMode) {
            next()
          } else {
            next('/mine')
          }
        } else {
          next()
        }
      }
    }
  }
})

export default router
